import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import API from "../../redux/api";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  selectedUserSelector,
  setUsers,
  setSelectedUser,
  usersSelector,
} from "../../redux/slices/userSlice";
import { useLazyFetchUsersQuery } from "../../services/UserServices/UserServices";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  BasicLoader,
  CreateUserModal,
  Header,
  LogoLoader,
  SearchInput,
  SelectableRoundedButtons,
  UserCard,
} from "../Components";

export default function AllUsersList() {
  const filters = ["View All", "Subscribed", "Unsubscribed"];
  const take = 10;

  const [selectedFilter, setSelectedFilter] = useState<string>("All Auditions");
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [isCreateUserModalVisible, setIsCreateUserModalVisible] =
    useState<boolean>(false);

  const dispatch = useIADispatch();
  const users = useIASelector(usersSelector);
  const selectedUser = useIASelector(selectedUserSelector);

  //APIS
  const [fetchUsersQuery, { isLoading, isFetching }] = useLazyFetchUsersQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchUsers();
      }, 300);
    } else {
      fetchUsers();
    }
    return () => clearTimeout(timeout);
  }, [searchText, selectedFilter]);

  const getIsSubscribed = () => {
    switch (selectedFilter) {
      case "View All":
        return "";
      case "Subscribed":
        return "true";
      case "Unsubscribed":
        return "false";
      default:
        return "";
    }
  };

  const fetchUsers = async () => {

    try {
      const response = await fetchUsersQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          isSubscribed: getIsSubscribed(),
        },
        true
      ).unwrap();

      if (response) {
        dispatch(setUsers(response));
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreUsers();
    }
  };

  const fetchMoreUsers = async () => {
    setIsLoadingMore(true);

    try {
      const [response] = await Promise.all([
        fetchUsersQuery({
          search: searchText,
          skip: skip + take,
          take: take,
          isSubscribed: getIsSubscribed(),
        }).unwrap(),
        asyncTimeout(300),
      ]);
      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!users) return;
        const newUsers = users.concat(response);
        dispatch(setUsers(newUsers));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const createEmptyUser = () => {};

  const renderAuditionsList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!users) return <div></div>;

    if (!users.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO USERS
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {users.map((user, index) => (
            <UserCard
              key={index}
              user={user}
              isSelected={selectedUser ? selectedUser.id === user.id : false}
              onClick={() => {
                dispatch(setSelectedUser(user));
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  const refreshUser = async () => {
    setSkip(0);
    dispatch(API.util.invalidateTags(["Users"]));
    await fetchUsers();
  };

  return (
    <div className="w-full">
      <CreateUserModal
        isOpen={isCreateUserModalVisible}
        closeModal={() => setIsCreateUserModalVisible(false)}
      />
      <Header
        text="Users"
        type="add"
        onClick={() => setIsCreateUserModalVisible(true)}
        isRefreshButton={true}
        onRefresh={refreshUser}
      />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderAuditionsList()}
    </div>
  );
}
