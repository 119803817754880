import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const RCUrl = "https://api.revenuecat.com/v1/";

const baseQuery = fetchBaseQuery({
  baseUrl: RCUrl,
  prepareHeaders: (headers) => {
    console.log(
      "process.env.REACT_APP_RC_API_KEY",
      process.env.REACT_APP_RC_API_KEY
    );
    headers.set("Authorization", `Bearer ${process.env.REACT_APP_RC_API_KEY}`);
    return headers;
  },
  timeout: 15000,
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

const RCAPI = createApi({
  reducerPath: "RCApi",
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
});

export default RCAPI;
