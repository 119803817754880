import React from "react";
import { useIASelector } from "../../redux/hooks";
import { selectedUserSelector } from "../../redux/slices/userSlice";
import {
  Header,
  UserManageMent,
  UserOverview,
  UserSubscription,
} from "../Components";

export default function UserDetails() {
  const selectedUser = useIASelector(selectedUserSelector);

  return (
    <div className="w-full">
      {selectedUser ? (
        <>
          <Header text={selectedUser.firstName + " " + selectedUser.lastName} />
          <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
            <UserOverview />
            <UserSubscription />
            <UserManageMent />
          </div>
        </>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No User Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a User
          </p>
        </div>
      )}
    </div>
  );
}
