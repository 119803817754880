import React, { useEffect } from "react";
import { User } from "../../types/MainTypes/User";
import { AsyncImage, UserAvatar } from "../Components";

type Props = {
  user: User;
  isSelected: boolean;
  onClick: () => void;
};

export default function UserCard({ user, isSelected, onClick }: Props) {
  const getUserSubscribedText = () => {
    if (user.productId === "iafree") return "Free Account";
    const subscriptionPlan = user.subscriptionPlan;
    if (!subscriptionPlan) return "No Plan";
    return user.subscriptionPlan;
  };

  return (
    <div
      className={`transition-all duration-200 w-full border-2 border-black flex flex-row items-center ${
        isSelected ? "text-white bg-black" : "text-black bg-white"
      } cursor-pointer p-2 group mb-4`}
      onClick={onClick}
    >
      <UserAvatar fileName={user.avatar} />
      <div className="ml-[10px]">
        <p className="font-Switzer font-bold text-2xl">
          {user.firstName + " " + user.lastName}
        </p>
        <div className="flex flex-row items-center">
          <p className="font-Switzer font-bold text-base mr-2">
            Subscribed User:
          </p>
          <p className="font-Switzer font-normal text-base">
            {getUserSubscribedText()}
          </p>
        </div>
      </div>
    </div>
  );
}
