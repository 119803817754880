import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("IAAdminToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

const API = createApi({
  reducerPath: "IAApi",
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    "Auditions",
    "AuditionTypes",
    "Posts",
    "PostTags",
    "Companies",
    "Agents",
    "Users",
    "Submissions",
    "DownloadedFile",
    "CompanyTypes",
    "AgentRepresentations",
    "LocationTagFilters",
    "PerformerTypeFilters",
    "CompanyTypeFilters",
    "UserStatistics",
  ],
  endpoints: () => ({}),
});

export default API;
