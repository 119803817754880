import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  BlockUserArgs,
  BlockUserReturnedData,
  CreateUserArgs,
  CreateUserReturnedData,
  DeleteUserArgs,
  FetchUsersArgs,
  FetchUsersReturnedData,
} from "./UserTypes";

const UserServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<FetchUsersReturnedData, FetchUsersArgs>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `admin/users?search=${args.search}&isSubscribed=${args.isSubscribed}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchUsersReturnedData };
      },
      providesTags: ["Users"],
    }),
    blockUser: builder.mutation<BlockUserReturnedData, BlockUserArgs>({
      query: (args) => ({
        url: `admin/users/${args.userId}/restriction`,
        method: "PUT",
        body: {
          restrict: args.restrict,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation<CreateUserReturnedData, CreateUserArgs>({
      query: (args) => ({
        url: "admin/create-user",
        method: "POST",
        body: args,
      }),
    }),
    deleteUser: builder.mutation<void, DeleteUserArgs>({
      query: (args) => ({
        url: `admin/users/${args.userId}/delete`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyFetchUsersQuery,
  useBlockUserMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
} = UserServices;
