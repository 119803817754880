import { configureStore } from "@reduxjs/toolkit";
import RCApi from "./RCApi";
import API from "./api";
import agentsSlice from "./slices/agentsSlice";
import auditionsSlice from "./slices/auditionsSlice";
import auditionTypesSlice from "./slices/auditionTypesSlice";
import companiesSlice from "./slices/companiesSlice";
import postsSlice from "./slices/postsSlice";
import postTagsSlice from "./slices/postTagsSlice";
import usersSlice from "./slices/userSlice";
import submissionsSlice from "./slices/submissionsSlice";
import overlaySlice from "./slices/overlaySlice";
import companyTypesSlice from "./slices/companyTypesSlice";
import agentRepresentationsSlice from "./slices/agentRepresentationsSlice";
import locationTagFiltersSlice from "./slices/locationTagFiltersSlice";
import performerTypeFiltersSlice from "./slices/performerTypeFiltersSlice";
import companyTypeFiltersSlice from "./slices/companyTypeFiltersSlice";

export const store = configureStore({
  reducer: {
    auditionsSlice: auditionsSlice,
    auditionTypesSlice: auditionTypesSlice,
    postsSlice: postsSlice,
    postTagsSlice: postTagsSlice,
    companiesSlice: companiesSlice,
    agentsSlice: agentsSlice,
    usersSlice: usersSlice,
    submissionsSlice: submissionsSlice,
    overlaySlice: overlaySlice,
    companyTypesSlice: companyTypesSlice,
    agentRepresentationsSlice: agentRepresentationsSlice,
    locationTagFiltersSlice: locationTagFiltersSlice,
    performerTypeFiltersSlice: performerTypeFiltersSlice,
    companyTypeFiltersSlice: companyTypeFiltersSlice,
    [API.reducerPath]: API.reducer,
    [RCApi.reducerPath]: RCApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      API.middleware,
      RCApi.middleware
    ),
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
